@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@tailwind base;
@tailwind components;
@tailwind forms;

// import custom code to place on correct position and prevent treeshaking
@import 'scss/alerts';
@import 'scss/backgrounds';
@import 'scss/forms';
@import 'scss/placeholder';
@import 'scss/tables';
@import 'scss/wysiwyg';
@import 'scss/helipopper';
@import 'scss/third-party-vendor';

@import '@angular/cdk/overlay-prebuilt.css';

* {
    font-variant-ligatures: none !important;
}

@tailwind utilities;

@layer base {
    html {
        overflow-x: hidden;
    }
}
