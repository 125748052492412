.tippy-box {
    display: inline-flex;
    font-size: 18px;
    line-height: 1.7;
    max-height: calc(80vh);
    border-radius: 10px;
    padding: 4px 6px;
    margin: 6px;
    overflow: hidden;
}

.tippy-content {
    overflow: auto;
}
