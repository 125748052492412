.wysiwyg-text {
    @apply space-y-3;

    ul {
        @apply list-disc ml-[1.2em];
    }

    ol {
        @apply list-decimal ml-[1.2em];
    }
}
