@layer components {
    .table {
        thead {
            @apply bg-gray-100;

            th {
                @apply py-3 px-5 text-left;
            }
        }

        tbody {
            td {
                @apply px-5 py-3 border-b;
            }
        }
    }
}
