.form-control {
    @apply font-sans appearance-none block w-full pl-3 pr-6 py-3 border-2 border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm;
}

.ng-submitted {
    .form-control {
        &.ng-invalid {
            @apply text-red-600 bg-red-100;

            &[type='text'] {
                @apply relative;
                background-image: url('/assets/icons/triangle-exclamation-solid.svg');
                background-repeat: no-repeat;
                background-position: top 50% right 5px;
            }
        }

        &.ng-invalid::placeholder {
            @apply text-red-600 font-semibold;
        }
    }
}
