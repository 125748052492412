.placeholder {
    min-height: 1em;

    mask-image: linear-gradient(
        100deg,
        #000 0%,
        #000 30%,
        rgba(0, 0, 0, 0.95) 40%,
        rgba(0, 0, 0, 0.8) 50%,
        rgba(0, 0, 0, 0.95) 60%,
        #000 70%,
        #000 100%
    );
    mask-size: 200% 100%;

    @apply bg-slate-500 animate-wave rounded-full;
}
